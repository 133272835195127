class categorySectionComponent extends Component {

    static name() {
        return "categorySectionComponent";
    }

    static componentName() {
        return "categorySectionComponent";
    }

    getProps() {
        return ['selectCategory',"displayCategory","open_by_default"];
    }

    data() {
        return {
            display: {
                categories: true,
                prices: true,
                filterHistory:true,
            }
        };
    }

    getTemplate() {
        return ` <section>
                    <template v-for="filter of $store.getters.getItemFilters.filter((x)=>{return x.fields.FilterActived})">
                        <filterProxyComponent :filterField="filter.fields" :open_by_default="open_by_default"></filterProxyComponent>
                    </template>
                </section>`;
    }
}

categorySectionComponent.registerComponent();
